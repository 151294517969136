export class Metric {
    public value: number;
    public alias: string;

    constructor(
        value: number,
        alias: string
    ) {
        this.value = value;
        this.alias = alias;
    }
}
