export class AppStateMessage {
    public text: string;
    public type: 'error'|'success';

    constructor(
        text: string,
        type:  'error'|'success'
    ) {
        this.text = text;
        this.type = type;
    }
}