import React from "react";
import {ThemeProvider, makeStyles} from "@material-ui/styles";
import HeaderContainer from "../../containers/HeaderContainer";
import Sidebar from "../Sidebar";
import Themes from "./themes";
import {LayoutProvider, useLayoutState} from "./context/LayoutContext";
import {UserProvider} from "./context/UserContext";
import {CssBaseline} from "@material-ui/core";
import classnames from "classnames";


function Layout({children}) {
    return (
        <ThemeProvider theme={Themes.default}>
            <LayoutProvider>
                <LayoutInput>
                    {children}
                </LayoutInput>;
            </LayoutProvider>
        </ThemeProvider>
    );
}

function LayoutInput({children}) {
    var classes = styles();
    var layoutState = useLayoutState();
    return (
        <UserProvider>

            <CssBaseline/>
            <div className={classes.root}>
                <>
                    <HeaderContainer/>
                    <Sidebar/>
                    <div
                        className={classnames(classes.content, {
                            [classes.contentShift]: layoutState.isSidebarOpened,
                        })}
                    >
                        <div className={classes.fakeToolbar}/>
                        {children}
                    </div>
                </>
            </div>
        </UserProvider>
    );
}

const styles = makeStyles(theme => ({
    root: {
        display: "flex",
        maxWidth: "100vw",
        overflowX: "hidden",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: `calc(100vw - 240px)`,
        minHeight: "100vh",
        position: 'relative'
    },
    contentShift: {
        width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    fakeToolbar: {
        ...theme.mixins.toolbar,
    },
}));

export default Layout;