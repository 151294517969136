import React, { useState } from 'react';
import {Tooltip, IconButton, Dialog, DialogTitle} from "@material-ui/core";
import {ReportAction} from "../entities/ReportAction";

export default function ReportActionComponent({reportAction}: {reportAction: ReportAction}) {
    const [isOpen, changeIsOpen] = useState(false);
    return (
        <>
            <Tooltip onClick={() => {changeIsOpen(!isOpen)}} title={reportAction.title}>
                <IconButton>
                    {reportAction.icon}
                </IconButton>
            </Tooltip>
            <Dialog onClose={() => {changeIsOpen(!isOpen)}} aria-labelledby="simple-dialog-title" open={isOpen}>
                <DialogTitle id="simple-dialog-title">{reportAction.title}</DialogTitle>
                {<reportAction.modalAction closeModalHandler={() => {changeIsOpen(false)}}/>}
            </Dialog>
        </>
    );
}