import {connect} from "react-redux";
import {RootState} from "../reducers";
import TableFilterComponent from '../components/TableFilterComponent';
import {ThunkDispatch} from "redux-thunk";
import {resetFilter} from "../actions/report";
import {getActionsByReportAlias} from '../services/reportService';

const mapStateToProps = (state: RootState) => {
    let checkedRows = 0;
    if(state.report.checked.all){
        checkedRows = state.report.totalRows - state.report.checked.without.length
    }else{
        checkedRows = state.report.checked.with.length;
    }

    return {
        filters:  state.report.filters,
        selectedFilters: state.report.selectedFilters,
        checkedRows: checkedRows,
        reportActions: getActionsByReportAlias(state.report.reportAlias)
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        resetFilterHandler: () => {
            dispatch(resetFilter())
        }
    }
};

const TableFilterContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TableFilterComponent);

export default TableFilterContainer

