import {Filter} from "../entities/Filter";
import {SelectedFilter} from "../entities/SelectedFilter";
import {store} from "../index";
import {setReportSelectFilterAction, removeReportSelectFilterAction, setPageAction} from "../reducers/report";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {makeStyles, Theme, createStyles} from "@material-ui/core";
import {fade} from "@material-ui/core/styles";
import {loadReport} from "../actions/report";
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';

export default ({filter}: { filter: Filter }) => {
    const dispatch = useDispatch();

    const selectedFilters: SelectedFilter[] = store.getState().report.selectedFilters;
    let selectedFilter: SelectedFilter | null = null;
    let searchQuery = '';
    selectedFilters.forEach((item: SelectedFilter) => {
        if (filter.alias === item.filter.alias) {
            selectedFilter = item;
            searchQuery = item.value;
        }
    });

    const handleChangeQuery = (value: string) => {
        if (null !== selectedFilter) {
            dispatch(removeReportSelectFilterAction(selectedFilter));
        }
        if (value.length > 0) {
            dispatch(setReportSelectFilterAction(new SelectedFilter(filter, value)));
            dispatch(setPageAction(0));
            dispatch(loadReport());
        }
    };

    return (
        <View searchQueryInit={searchQuery} handler={handleChangeQuery}/>
    )
};

const View = ({searchQueryInit, handler}: {searchQueryInit: string, handler: (value: string) => void}) => {
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState(searchQueryInit);

    const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        handler(event.target.value);
    };

    return (
    <div className={classes.search}>
        <div className={classes.searchIcon}>
            <SearchIcon/>
        </div>
        <InputBase
            placeholder="Search…"
            classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
            }}
            onChange={handleChangeSearchQuery}
            value={searchQuery}
            inputProps={{'aria-label': 'search'}}
        />
    </div>)
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.black, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.black, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: 'auto',
            },
        },
        searchIcon: {
            width: theme.spacing(7),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 7),
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('md')]: {
                width: 200,
            },
        },
    }));
