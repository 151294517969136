import {Column} from '../entities/Column';
import {OrderColumn, ColumnOrderDirections} from '../entities/OrderColumn';
import {Row} from '../entities/Row';
import {SelectedFilter} from '../entities/SelectedFilter';
import {FilterGroup} from '../entities/FilterGroup';

export const SET_ROWS = 'SET_ROWS';
export const SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SET_PAGE = 'SET_PAGE';
export const CHANGE_COLUMN_ORDER_DIRECTION = 'CHANGE_COLUMN_ORDER_DIRECTION';
export const SET_REPORT_ALIAS = 'SET_REPORT_ALIAS';
export const SET_REPORT_FILTERS = 'SET_REPORT_FILTERS';

export const REPORT_CHANGE_CHECKED_ALL = 'REPORT_CHANGE_CHECKED_ALL';
export const REPORT_ADD_CHECKED_TO_WITHOUT = 'REPORT_ADD_CHECKED_TO_WITHOUT';
export const REPORT_REMOVE_CHECKED_BY_WITHOUT = 'REPORT_REMOVE_CHECKED_BY_WITHOUT';
export const REPORT_ADD_CHECKED_TO_WITH = 'REPORT_ADD_CHECKED_TO_WITH';
export const REPORT_REMOVE_CHECKED_BY_WITH = 'REPORT_REMOVE_CHECKED_BY_WITH';

export const SET_REPORT_SELECT_FILTER = 'SET_REPORT_SELECT_FILTER';
export const SET_REPORT_SELECT_FILTER_ARRAY = 'SET_REPORT_SELECT_FILTER_ARRAY';

export const REMOVE_REPORT_SELECT_FILTER = 'REMOVE_REPORT_SELECT_FILTER';

const defaultState: ReportState = {
    checked: {all: false, without: [], with: []},
    columns: [],
    rows: [],
    totalRows: 0,
    rowsPerPage: 10,
    page: 0,
    reportAlias: null,
    filters: [],
    selectedFilters: []
};

const report = (state: ReportState = defaultState, action: ReportActionTypes): ReportState => {
    switch (action.type) {
        case SET_ROWS:
            return {...state, rows: action.rows, totalRows: action.totalRows};
        case SET_COLUMNS:
            return {...state, columns: action.columns};
        case SET_ROWS_PER_PAGE:
            return {...state, rowsPerPage: action.rowsPerPage};
        case SET_PAGE:
            return {...state, page: action.page};
        case SET_REPORT_ALIAS:
            return {...defaultState, reportAlias: action.reportAlias};
        case SET_REPORT_FILTERS:
            return {...state, filters: action.filters};
        case SET_REPORT_SELECT_FILTER:
            return {
                ...state,
                selectedFilters: [
                    ...state.selectedFilters,
                        //.filter((selectFilter: SelectedFilter) => selectFilter.filter !== action.selectFilter.filter),
                action.selectFilter]
            };
        case SET_REPORT_SELECT_FILTER_ARRAY:
            return {...state, selectedFilters: action.selectFilterArray};
        case REMOVE_REPORT_SELECT_FILTER:
            return {
                ...state,
                selectedFilters: state.selectedFilters.filter((selectFilter: SelectedFilter) => selectFilter !== action.selectFilter)
            };
        case REPORT_CHANGE_CHECKED_ALL:
            return {...state, checked: {...state.checked, all: !state.checked.all}};
        case REPORT_REMOVE_CHECKED_BY_WITHOUT:
            return {
                ...state, checked: {
                    ...state.checked, without: state.checked.without.filter((item: number) => {
                        return item !== action.id;
                    })
                }
            };
        case REPORT_REMOVE_CHECKED_BY_WITH:
            return {
                ...state, checked: {
                    ...state.checked, with: state.checked.with.filter((item: number) => {
                        return item !== action.id;
                    })
                }
            };
        case REPORT_ADD_CHECKED_TO_WITH:
            return {
                ...state, checked: {
                    ...state.checked, with: [...state.checked.with, action.id]
                }
            };
        case REPORT_ADD_CHECKED_TO_WITHOUT:
            return {
                ...state, checked: {
                    ...state.checked, without: [...state.checked.without, action.id]
                }
            };
        case CHANGE_COLUMN_ORDER_DIRECTION:
            return {
                ...state, columns: state.columns.map((column) => {
                    let newColumn = column;
                    if (undefined !== column.order) {
                        let direction: ColumnOrderDirections = null;
                        if (column.id === action.columnId) {
                            switch (column.order.direction) {
                                case null:
                                    direction = 'desc';
                                    break;
                                case 'asc':
                                    direction = null;
                                    break;
                                case 'desc':
                                    direction = 'asc';
                                    break;
                            }
                        }

                        newColumn = new Column(
                            column.id,
                            column.label,
                            new OrderColumn(direction, column.id),
                        );
                    }

                    return newColumn;
                })
            };

        default:
            return state;
    }
};

export default report;


interface SetColumnsAction {
    type: typeof SET_COLUMNS,
    columns: Column[]
}

interface SetRowsAction {
    type: typeof SET_ROWS,
    rows: Row[],
    totalRows: number
}

interface SetRowsPerPageAction {
    type: typeof SET_ROWS_PER_PAGE
    rowsPerPage: number
}

interface SetPageAction {
    type: typeof SET_PAGE
    page: number
}

interface ChangeColumnOrderDirectionAction {
    type: typeof CHANGE_COLUMN_ORDER_DIRECTION,
    columnId: string
}

interface SetReportAliasAction {
    type: typeof SET_REPORT_ALIAS,
    reportAlias: string
}

interface SetReportFiltersAction {
    type: typeof SET_REPORT_FILTERS,
    filters: FilterGroup[]
}

interface SetReportSelectFilterAction {
    type: typeof SET_REPORT_SELECT_FILTER,
    selectFilter: SelectedFilter
}

interface SetReportSelectFilterArrayAction {
    type: typeof SET_REPORT_SELECT_FILTER_ARRAY,
    selectFilterArray: SelectedFilter[]
}


interface RemoveReportSelectFilterAction {
    type: typeof REMOVE_REPORT_SELECT_FILTER,
    selectFilter: SelectedFilter
}

interface ReportRemoveCheckedWithoutAction {
    type: typeof REPORT_REMOVE_CHECKED_BY_WITHOUT,
    id: number
}

interface ReportAddCheckedWithoutAction {
    type: typeof REPORT_ADD_CHECKED_TO_WITHOUT,
    id: number
}

interface ReportRemoveCheckedWithAction {
    type: typeof REPORT_REMOVE_CHECKED_BY_WITH,
    id: number
}

interface ReportAddCheckedWithAction {
    type: typeof REPORT_ADD_CHECKED_TO_WITH,
    id: number
}


interface ReportChangeCheckedAllAction {
    type: typeof REPORT_CHANGE_CHECKED_ALL
}

export interface ReportState {
    columns: Column[],
    rows: Row[],
    totalRows: number,
    rowsPerPage: number,
    page: number,
    reportAlias: string | null,
    filters: FilterGroup[],
    selectedFilters: SelectedFilter[],
    checked: { all: boolean, without: number[], with: number[] }
}

export function reportRemoveCheckedWithoutAction(id: number): ReportRemoveCheckedWithoutAction {
    return {
        type: REPORT_REMOVE_CHECKED_BY_WITHOUT,
        id: id
    }
}

export function reportRemoveCheckedWithAction(id: number): ReportRemoveCheckedWithAction {
    return {
        type: REPORT_REMOVE_CHECKED_BY_WITH,
        id: id
    }
}

export function reportAddCheckedWithoutAction(id: number): ReportAddCheckedWithoutAction {
    return {
        type: REPORT_ADD_CHECKED_TO_WITHOUT,
        id: id
    }
}

export function reportAddCheckedWithAction(id: number): ReportAddCheckedWithAction {
    return {
        type: REPORT_ADD_CHECKED_TO_WITH,
        id: id
    }
}

export function reportChangeCheckedAllAction(): ReportChangeCheckedAllAction {
    return {
        type: REPORT_CHANGE_CHECKED_ALL
    }
}

export function setColumnsAction(columns: Column[]): SetColumnsAction {
    return {
        type: SET_COLUMNS,
        columns: columns
    }
}

export function setRowsAction(rows: Row[], totalRows: number): SetRowsAction {
    return {
        type: SET_ROWS,
        rows: rows,
        totalRows: totalRows
    }
}

export function setRowsPerPageAction(rowsPerPage: number): SetRowsPerPageAction {
    return {
        type: SET_ROWS_PER_PAGE,
        rowsPerPage: rowsPerPage
    }
}

export function setPageAction(page: number): SetPageAction {
    return {
        type: SET_PAGE,
        page: page
    }
}

export function changeColumnOrderDirectionAction(columnId: string): ChangeColumnOrderDirectionAction {
    return {
        type: CHANGE_COLUMN_ORDER_DIRECTION,
        columnId: columnId
    }
}

export function setReportAliasAction(reportAlias: string): SetReportAliasAction {
    return {
        type: SET_REPORT_ALIAS,
        reportAlias: reportAlias
    }
}

export function setReportFiltersAction(filters: FilterGroup[]): SetReportFiltersAction {
    return {
        type: SET_REPORT_FILTERS,
        filters: filters
    }
}

export function setReportSelectFilterAction(selectFilter: SelectedFilter): SetReportSelectFilterAction {
    return {
        type: SET_REPORT_SELECT_FILTER,
        selectFilter: selectFilter
    }
}

export function setReportSelectFilterArrayAction(selectFilterArray: SelectedFilter[]): SetReportSelectFilterArrayAction {
    return {
        type: SET_REPORT_SELECT_FILTER_ARRAY,
        selectFilterArray: selectFilterArray
    }
}

export function removeReportSelectFilterAction(selectFilter: SelectedFilter): RemoveReportSelectFilterAction {
    return {
        type: REMOVE_REPORT_SELECT_FILTER,
        selectFilter: selectFilter
    }
}


export type ReportActionTypes =
    SetColumnsAction
    | SetRowsAction
    | ChangeColumnOrderDirectionAction
    | SetRowsPerPageAction
    | SetPageAction
    | SetReportAliasAction
    | SetReportFiltersAction
    | SetReportSelectFilterAction
    | RemoveReportSelectFilterAction
    | SetReportSelectFilterArrayAction
    | ReportRemoveCheckedWithoutAction
    | ReportRemoveCheckedWithAction
    | ReportAddCheckedWithAction
    | ReportAddCheckedWithoutAction
    | ReportChangeCheckedAllAction;