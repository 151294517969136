import React from "react";
import {SelectedFilter} from "../entities/SelectedFilter";
import {removeReportSelectFilterAction, setPageAction} from "../reducers/report";
import {Chip} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {loadReport} from "../actions/report";


export default ({selectedFilter}: { selectedFilter: SelectedFilter }) => {
    const dispatch = useDispatch();
    const handleDelete = () => {
        dispatch(removeReportSelectFilterAction(selectedFilter));
        dispatch(setPageAction(0));
        dispatch(loadReport());
    };
    return (
        <Chip label={selectedFilter.getText()} onDelete={handleDelete} color="primary"/>
    )
};