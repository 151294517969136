import {setUserAction, resetUserAction} from '../reducers/user';
import {setIsLoadingAction, setMessageAction, removeAllMessageAction} from '../reducers/appState';
import { ThunkAction,  } from 'redux-thunk'
import { Action } from 'redux';
import {RootState} from "../reducers";
import {User} from "../entities/User";
import {AppStateMessage} from "../entities/AppStateMessage";
import {login as loginApi, LoginResponseInterface} from "../api/user";
import history from "../services/historyService";
import {store} from "../index";


export const loginUser = (login: string, password: string): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(removeAllMessageAction());
    dispatch(setIsLoadingAction(true));
    loginApi(login, password).then((loginResponse: LoginResponseInterface) => {
        store.dispatch(setIsLoadingAction(false));
        const user: User = new User(login, loginResponse.token);
        dispatch(setUserAction(user));
        history.push('/dashboard');
    }).catch((e) => {
        store.dispatch(setMessageAction(new AppStateMessage(e, 'error')));
        store.dispatch(setIsLoadingAction(false));
    });
};


export const logoutUser = () => {
    store.dispatch(resetUserAction());
    history.push('/login');
    store.dispatch(removeAllMessageAction());
};

