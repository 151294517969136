import {requestApiWithToken, RequestOptionsInterface} from './requestApi';
import {store} from "../index";

export const sendEmails = (data: {theme: string, text: string, alias: null|string}) => {
    const report = store.getState().report;
    const requestOptions: RequestOptionsInterface = {
        method: 'POST',
        body: JSON.stringify({
            alias: data.alias,
            theme: data.theme,
            text: data.text,
            checked: report.checked,
            selectedFilters: report.selectedFilters
        })
    };
    return requestApiWithToken(`report/actions/send_emails`, requestOptions);
};

export const sendTelegram = (data: {text: string, alias: null|string}) => {
    const report = store.getState().report;
    const requestOptions: RequestOptionsInterface = {
        method: 'POST',
        body: JSON.stringify({
            alias: data.alias,
            text: data.text,
            checked: report.checked,
            selectedFilters: report.selectedFilters
        })
    };
    return requestApiWithToken(`report/actions/send_telegram`, requestOptions);
};

export const payedToPartner = () => {
    const report = store.getState().report;
    const requestOptions: RequestOptionsInterface = {
        method: 'POST',
        body: JSON.stringify({
            checked: report.checked,
            selectedFilters: report.selectedFilters
        })
    };
    return requestApiWithToken(`report/actions/payedToPartner`, requestOptions);
};

