export class ReportAction {
    public alias: string;
    public title: string;
    public icon: any;
    public modalAction: any;

    constructor(
        alias: string,
        title: string,
        icon: any,
        modalAction: any
    ) {
        this.alias = alias;
        this.title = title;
        this.icon = icon;
        this.modalAction = modalAction;
    }
}


