import {connect} from 'react-redux'
import React, {Component} from 'react';
import {loadMetric} from "../actions/metrics";
import PageTitle from "../components/base/PageTitle";
import Widget from "../components/Widget";
import {Grid} from "@material-ui/core";
import {findMetricByAlias} from '../services/metricService'
import {RootState} from "../reducers";
import {Metric} from "../entities/Metric";
import moment, {Moment} from 'moment';

class Report extends Component<{metrics: Metric[], loadMetric: (metricName: string, alias: string, dateFrom?: Moment, dateTo?: Moment) => void }, {}> {

    componentDidMount() {
        this.props.loadMetric('METRIC_PAYMENTS', 'paymentToday', moment().startOf('day'));
        this.props.loadMetric('METRIC_PAYMENTS', 'paymentByWeek', moment().startOf('isoWeek'));
        this.props.loadMetric('METRIC_PAYMENTS', 'paymentByMonth', moment().startOf('month'));
        this.props.loadMetric('METRIC_PAYMENTS', 'paymentByLastMonth', moment().startOf('month').subtract({month: 1}), moment().startOf('month'));

        this.props.loadMetric('METRIC_USERS', 'registrationsAll');
        this.props.loadMetric('METRIC_USERS', 'registrationsToday', moment().startOf('day'));
        this.props.loadMetric('METRIC_USERS', 'registrationsByWeek', moment().startOf('isoWeek'));
        this.props.loadMetric('METRIC_USERS', 'registrationsByMonth', moment().startOf('month'));
        this.props.loadMetric('METRIC_USERS', 'registrationsByLastMonth', moment().startOf('month').subtract({month: 1}), moment().startOf('month'));
    }

    render() {
        const paymentToday = findMetricByAlias('paymentToday', this.props.metrics);
        const paymentByWeek = findMetricByAlias('paymentByWeek', this.props.metrics);
        const paymentByMonth = findMetricByAlias('paymentByMonth', this.props.metrics);
        const paymentByLastMonth = findMetricByAlias('paymentByLastMonth', this.props.metrics);

        const registrationsAll = findMetricByAlias('registrationsAll', this.props.metrics);
        const registrationsToday = findMetricByAlias('registrationsToday', this.props.metrics);
        const registrationsByWeek = findMetricByAlias('registrationsByWeek', this.props.metrics);
        const registrationsByMonth = findMetricByAlias('registrationsByMonth', this.props.metrics);
        const registrationsByLastMonth = findMetricByAlias('registrationsByLastMonth', this.props.metrics);

        return (
            <>
                <PageTitle title="Dashboard"/>
                <Grid container spacing={4}>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Widget
                            title={'Payment today'}
                            mainValue={(null !== paymentToday) ? paymentToday.value : 0}
                            values={[{
                                title: 'Week',
                                value: (null !== paymentByWeek) ? paymentByWeek.value : 0
                            }, {
                                title: 'Month',
                                value: (null !== paymentByMonth) ? paymentByMonth.value : 0
                            }, {
                                title: 'Last Month',
                                value: (null !== paymentByLastMonth) ? paymentByLastMonth.value : 0
                            }]}
                        />
                    </Grid>
                    <Grid item lg={3} md={4} sm={6} xs={12}>
                        <Widget
                            title={'Registrations'}
                            mainValue={(null !== registrationsAll) ? registrationsAll.value : 0}
                            values={[{
                                title: 'Today',
                                value: (null !== registrationsToday) ? registrationsToday.value : 0
                            }, {
                                title: 'Week',
                                value: (null !== registrationsByWeek) ? registrationsByWeek.value : 0
                            }, {
                                title: 'Month',
                                value: (null !== registrationsByMonth) ? registrationsByMonth.value : 0
                            }, {
                                title: 'Last Month',
                                value: (null !== registrationsByLastMonth) ? registrationsByLastMonth.value : 0
                            }]}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }
}


const mapDispatchToProps = (dispatch: any) => {
    return {
        loadMetric: (metricName: string, alias: string, dateFrom?: Moment, dateTo?: Moment) => {
            dispatch(loadMetric(metricName, alias, dateFrom, dateTo));
        }
    };
};

const mapStateToProps = (state: RootState) => {
    return {
        metrics: state.metrics
    }
};


const ReportContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Report);

export default ReportContainer

