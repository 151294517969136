import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHeadContainer from '../containers/TableHeadContainer';
import TableBodyContainer from "../containers/TableBodyContainer";
import TableFilterContainer from "../containers/TableFilterContainer";
import TablePaginationContainer from "../containers/TablePaginationContainer";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    tableWrapper: {
        overflow: 'auto',
    },
});

export default function ReportComponent() {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
                <TableFilterContainer/>
                <Table stickyHeader aria-label="sticky table">
                    <TableHeadContainer/>
                    <TableBodyContainer/>
                </Table>
            </div>
            <TablePaginationContainer/>
        </Paper>
    );
}