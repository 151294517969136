import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Paper, Grid, FormControl, Typography, TextField, Button, FormLabel, Tabs, Tab} from '@material-ui/core';
import {Link as LinkRouter} from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Table from "@material-ui/core/Table";
import TableHeadContainer from "../containers/TableHeadContainer";
import TableBodyContainer from "../containers/TableBodyContainer";
import TablePaginationContainer from "../containers/TablePaginationContainer";

const useStyles = makeStyles({
    root: {
        width: '100%',
        padding: 20
    }
});

export default  ({fields, userAddBalanceHandler, clickTabHandler, activeTab, tabs}: { fields: any, userAddBalanceHandler: (countLeads: number) => void, clickTabHandler: (tabName: string) => void, activeTab: string, tabs: string[]}) => {
    const classes = useStyles();
    const [countLeads, setCountLeads] = useState(0);
    const changeCountLeadsHandler = (event: React.ChangeEvent<HTMLInputElement>) => setCountLeads(parseInt(event.target.value));


    return (
        <Paper className={classes.root}>
            <Link component={LinkRouter} to={'/report/user'}>Назад</Link>
            <br/><br/>
            {null !== fields &&
            <Grid container>
                <Grid item xs={6}>
                    <Typography>id: {fields.id}</Typography>
                    <Typography>email: {fields.email}</Typography>
                    <Typography>balance: {fields.balance}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl>
                        <FormLabel>Пополнить баланс</FormLabel>
                        <TextField
                            label="count leads"
                            type="number"
                            onChange={changeCountLeadsHandler}
                            value={countLeads}
                        />
                        <Button onClick={() => {
                            userAddBalanceHandler(countLeads);
                            setCountLeads(0);
                        }}>OK</Button>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Paper >
                        <Tabs value={tabs.indexOf(activeTab)}>
                            {tabs.map((tab: string) =>  <Tab key={tab} onClick={() => clickTabHandler(tab)} label={tab} />)}
                        </Tabs>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHeadContainer/>
                                <TableBodyContainer/>
                            </Table>
                        <TablePaginationContainer/>
                    </Paper>
                </Grid>

            </Grid>}

        </Paper>
    );
}