import React, {useState} from 'react';
import {makeStyles, Grid, FormControl, Button, Theme, createStyles} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 15
        }
    }));

export default function PayedToPartnerComponent({sendHandler, checkedRows}: { sendHandler: () => void, checkedRows: number }) {
    const classes = useStyles();


    const buttonClickHandler = () => {
        sendHandler();
    };

    return (
        <Grid className={classes.container}>
            <p>{checkedRows} оплат</p>
            <FormControl>
                <Button onClick={buttonClickHandler}>отметить</Button>
            </FormControl>
        </Grid>
    );
}