import {logoutUser} from "../actions/user";


export function handleResponse(response: any)  {
    return response.json().then((data: any) => {
        if (!response.ok) {
            const error = (data && data.error &&  data.error.message) || 'Неизвестная ошибка';
            return Promise.reject(error);
        }
        return data;
    }, (errorData: Promise<never>) => {
        return Promise.reject('Неизвестная ошибка');
    });
}


export function handleResponseWithLogout(response: any)  {
    return response.json().then((data: any) => {
        if (!response.ok) {
            if (response.status === 401) {
                logoutUser();
            }
            const error = (data && data.error &&  data.error.message) || 'Неизвестная ошибка';
            return Promise.reject(error);
        }
        return data;
    }, (errorData: Promise<never>) => {
        return Promise.reject('Неизвестная ошибка');
    });
}


