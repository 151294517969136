import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import {rootReducer} from './reducers'
import thunk from 'redux-thunk'
import {setUserAction} from "./reducers/user";
import {User} from "./entities/User";

export const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

const userDataEncode = localStorage.getItem('user');
if(userDataEncode){
    const userDataDecode = JSON.parse(userDataEncode);
    store.dispatch(setUserAction(new User(userDataDecode.login, userDataDecode.token, userDataDecode.name)))
}

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
