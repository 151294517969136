import { handleResponse, handleResponseWithLogout } from './handleResponse';
import {store} from "../index";

const apiUrl = 'https://api.leadforms.ru/v2';
export interface RequestOptionsInterface {
    headers?: {},
    method: 'POST'|'GET'|'PATCH'|'DELETE',
    body?: string
}

export function requestApi(path: string, requestOptions: RequestOptionsInterface) {
    return fetch(`${apiUrl}/${path}`, requestOptions).then(handleResponse).catch(
        error => {
            return Promise.reject(typeof error === 'string' ? error : 'Неизвестная ошибка');
        }
    )
}

export function requestApiWithToken(path: string, requestOptions: RequestOptionsInterface) {
    return fetch(`${apiUrl}/${path}`, authHeader(requestOptions)).then(handleResponseWithLogout).catch(
        error => {
            return Promise.reject(typeof error === 'string' ? error : 'Неизвестная ошибка');
        }
    )
}

function authHeader(requestOptions: object) {
    const user = store.getState().user;
    if(null !== user){
        return { ...requestOptions, headers:{'Content-Type': 'application/json', 'Authorization': 'Bearer ' +  user.token}};
    }else {
        return requestOptions;
    }
}