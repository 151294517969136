import {requestApiWithToken, RequestOptionsInterface} from './requestApi';

export const getUserItem = (id: number) => {
    const requestOptions: RequestOptionsInterface = {
        method: 'POST'
    };
    return requestApiWithToken(`report/REPORT_USER/${id}`, requestOptions);
};

export const userAddBalance = (userId: number, countLeads: number) => {
    const requestOptions: RequestOptionsInterface = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ countLeads: countLeads, userId: userId })
    };
    return requestApiWithToken(`report/actions/user_add_balance`, requestOptions);


};
