import React from "react";
import Sidebar from "./base/Sidebar";
import {
    Home as HomeIcon,
    AttachMoney as PaymentIcon,
    Group as GroupIcon,
    QuestionAnswer as QuestionAnswerIcon,
    ContactMail as ContactMailIcon,
    Message as Message
} from "@material-ui/icons";

const structure = [
    {
        id: 1,
        label: "Dashboard",
        link: "/dashboard",
        icon: <HomeIcon/>,
    },
    { id: 2, type: "divider" },
    { id: 3, type: "title", label: "REPORTS" },
    {
        id: 4,
        label: "Users",
        link: "/report/user",
        icon: <GroupIcon/>,
    },
    {
        id: 5,
        label: "Quizzes",
        link: "/report/quiz",
        icon: <QuestionAnswerIcon/>,
    },
    {
        id: 6,
        label: "Payments",
        link: "/report/payment",
        icon: <PaymentIcon/>,
    },
    {
        id: 7,
        label: "Leads",
        link: "/report/lead",
        icon: <ContactMailIcon/>,
    },
    {
        id: 8,
        label: "Actions",
        link: "/report/action",
        icon: <Message/>,
    },
    {
        id: 9,
        label: "Sessions",
        link: "/report/session",
        icon: <Message/>,
    }

];

export default () => {
    return (
        <Sidebar structure={structure}/>
    );
}