import {connect} from "react-redux";
import TableRowComponent from "../components/TableRowComponent";
import {RootState} from "../reducers";
import {getActionsByReportAlias} from "../services/reportService";
import {ThunkDispatch} from "redux-thunk";
import {store} from "../index";
import {reportAddCheckedWithAction, reportAddCheckedWithoutAction, reportRemoveCheckedWithAction, reportRemoveCheckedWithoutAction, ReportState} from "../reducers/report";
import {Row} from "../entities/Row";

const calc = (reportState: ReportState, row: Row): boolean => {
    if (reportState.checked.all && reportState.checked.without.indexOf(row.id) === -1) {
        return true;
    }else if (reportState.checked.with.indexOf(row.id) !== -1) {
        return true;
    }
    return false;
};

const mapStateToProps = (state: RootState, own: { row: Row }) => {
    const report = state.report;
    return {
        columns: report.columns,
        withChecked: getActionsByReportAlias(report.reportAlias).length > 0,
        checked: calc(report, own.row),
        link: 'REPORT_USER' === state.report.reportAlias ? `/report/user/${own.row.id}` : undefined
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, own: { row: Row }) => {
    return {
        clickCheckboxHandler: () => {
            const report = store.getState().report;
            const row = own.row;

            if (report.checked.all) {
                if(report.checked.without.indexOf(row.id) === -1){
                    dispatch(reportAddCheckedWithoutAction(row.id))
                }else{
                    dispatch(reportRemoveCheckedWithoutAction(row.id))
                }
            } else {
                if(report.checked.with.indexOf(row.id) === -1){
                    dispatch(reportAddCheckedWithAction(row.id))
                }else{
                    dispatch(reportRemoveCheckedWithAction(row.id))
                }
            }
        }
    }
};

const TableRowContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TableRowComponent);

export default TableRowContainer;