import {User} from '../entities/User';

export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';

const user = (state: User|null = null, action: UserActionTypes): User|null => {
    switch (action.type) {
        case SET_USER:
            localStorage.setItem('user', JSON.stringify(action.user));
            return action.user;
        case RESET_USER:
            localStorage.removeItem('user');
            return null;
        default:
            return state;
    }
};

export default user;

interface SetUserAction {
    type: typeof SET_USER,
    user: User
}

interface ResetUserAction {
    type: typeof RESET_USER
}

export function setUserAction(user: User): SetUserAction {
    return {
        type: SET_USER,
        user: user
    }
}

export function resetUserAction(): ResetUserAction {
    return {
        type: RESET_USER
    }
}

export type UserActionTypes = SetUserAction|ResetUserAction;