import UserItemComponent from '../components/UserItemComponent'
import React, {useState, useEffect} from 'react';
import {getUserItem, userAddBalance} from '../api/userReport';
import {setIsLoadingAction, setMessageAction} from "../reducers/appState";
import {store} from "../index";

import {AppStateMessage} from "../entities/AppStateMessage";
import {SelectedFilter} from "../entities/SelectedFilter";
import {Filter} from "../entities/Filter";
import {setReportAliasAction, setReportSelectFilterAction} from "../reducers/report";
import {loadReport} from "../actions/report";
import {useDispatch} from "react-redux";


export default ({match}: {match: any}) => {

    const userId = match.params.id;
    const [fields, setFields] = useState(null);
    const tabs = [
        'REPORT_QUIZ',
        'REPORT_PAYMENT',
        'REPORT_LEAD',
        'REPORT_SESSION'
    ];
    const [activeTab, setActiveTab] = useState('REPORT_QUIZ');

    const loadUserInfo = () =>  {
        store.dispatch(setIsLoadingAction(true));
        getUserItem(userId).then((data: any) => {
            setFields(data.data);
            store.dispatch(setIsLoadingAction(false));
        }).catch((e) => {
            store.dispatch(setMessageAction(new AppStateMessage(e, 'error')));
            store.dispatch(setIsLoadingAction(false));
        });
    };

    const dispatch = useDispatch();

    const loadTable = (alias: string) =>  {
        dispatch(setReportAliasAction(alias));
        dispatch(setReportSelectFilterAction(new SelectedFilter(new Filter('sss', 'select', 'FILTER_USER_ID', '', []), userId)));
        dispatch(loadReport());
    };

    const userAddBalanceHandler = (countLeads: number) => {
        store.dispatch(setIsLoadingAction(true));
        userAddBalance(userId, countLeads).then((data: any) => {
            loadUserInfo();
            store.dispatch(setMessageAction(new AppStateMessage('Баланс пополнен', 'success')));
        }).catch((e) => {
            store.dispatch(setMessageAction(new AppStateMessage(e, 'error')));
            store.dispatch(setIsLoadingAction(false));
        });
    };


    useEffect(() => {
        loadUserInfo();
        loadTable(activeTab);
        }, []);

    return <UserItemComponent clickTabHandler={(tabName: string) => {setActiveTab(tabName);loadTable(tabName);}} activeTab={activeTab} tabs={tabs} userAddBalanceHandler={userAddBalanceHandler} fields={fields}/>
}