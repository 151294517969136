import {Metric} from '../entities/Metric';

export const SET_METRIC = 'SET_METRIC';

const metrics = (state: Metric[] = [], action: MetricsActionTypes): Metric[] => {
    switch (action.type) {
        case SET_METRIC:
            return [...state, action.metric];
        default:
            return state;
    }
};

export default metrics;

interface SetMetricAction {
    type: typeof SET_METRIC,
    metric: Metric
}

export function setMetricAction(metric: Metric): SetMetricAction {
    return {
        type: SET_METRIC,
        metric: metric
    }
}

export type MetricsActionTypes = SetMetricAction;