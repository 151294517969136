import {Filter} from './Filter';
import moment from 'moment';
import {FilterValue} from "./FilterValue";

export class SelectedFilter {
    public filter: Filter;
    public value: any;

    constructor(
        filter: Filter,
        value: any
    ) {
        this.filter = filter;
        this.value = value;
    }

    public getText(): string {
        switch (this.filter.type) {
            case "date":
                return `${this.filter.getFullLabel()} ${moment(this.value).format('DD.MM.Y')}`;
            case "search":
                return `${this.filter.getFullLabel()}: ${this.value}`;
            case "multiselect":
                const filterValues = this.filter.filterValues;
                let title = this.value;
                if (undefined !== filterValues) {
                    filterValues.map((filterValue: FilterValue) => {
                        if (filterValue.id === this.value) {
                            title = filterValue.title;
                        }
                    })
                }

                return `${this.filter.getFullLabel()}: ${title}`;
            default:
                return this.filter.getFullLabel();

        }

    }
}