import React from 'react';
import {makeStyles, lighten} from '@material-ui/core/styles';
import {Toolbar, Tooltip, IconButton, Popover, Grid, Typography, Button} from '@material-ui/core';
import {FilterList as FilterListIcon} from '@material-ui/icons';
import {FilterGroup} from "../entities/FilterGroup";
import {SelectedFilter} from "../entities/SelectedFilter";
import FilterGroupContainer from '../containers/FilterGroupContainer';
import ReportActionContainer from '../containers/ReportActionContainer';
import SelectedFilterContainer from '../containers/SelectedFilterContainer';
import {ReportAction} from '../entities/ReportAction';
import clsx from 'clsx';


const useToolbarStyles = makeStyles(theme => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    containerPopover: {
        padding: theme.spacing(2),
    }
}));

export default ({reportActions, checkedRows, filters, selectedFilters, resetFilterHandler}: {reportActions: ReportAction[], checkedRows: number, filters: FilterGroup[], selectedFilters: SelectedFilter[], resetFilterHandler: () => void }) => {
    if (filters.length === 0) {
        return null;
    }

    const classes = useToolbarStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: checkedRows > 0,
            })}
        >
            <Grid
                container
                direction="column"
            >
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                >
                    <Grid>
                        {checkedRows > 0 &&
                        <Typography color="inherit" variant="subtitle1">
                            {checkedRows} selected
                        </Typography>
                        }
                    </Grid>
                    {checkedRows > 0 ?
                        <Grid>
                            {reportActions.map((reportAction: ReportAction, index: number) =>
                                <ReportActionContainer
                                key={index} reportAction={reportAction}
                                />)}
                        </Grid>
                        :
                    <Grid>
                        {selectedFilters.length > 0 &&
                        <Button onClick={resetFilterHandler} color='secondary'>RESET</Button>}
                        <Tooltip title="Filter list">
                            <IconButton aria-label="filter list" color={open ? 'primary' : 'default'}
                                        aria-describedby={id}
                                        onClick={handleClick}>
                                <FilterListIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>}
                </Grid>
                <Grid container>
                    {selectedFilters.map((selectedFilter: SelectedFilter, index: number) => <SelectedFilterContainer
                        key={index} selectedFilter={selectedFilter}/>)}
                </Grid>
            </Grid>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Grid className={classes.containerPopover}>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                    >
                        <Typography>FILTERS</Typography>
                        {selectedFilters.length > 0 &&
                        <Button onClick={resetFilterHandler} color='secondary'>RESET</Button>}
                    </Grid>

                    <br/>
                    {filters.map((filterGroup: FilterGroup, index: number) => <FilterGroupContainer key={index}
                                                                                                    filterGroup={filterGroup}/>)}
                </Grid>
            </Popover>
        </Toolbar>
    );
};
