import React from "react";
import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from "@material-ui/icons";
import {
    Theme,
    createStyles,
    makeStyles,
    Grid
} from "@material-ui/core";

export default function TableSortLabel({direction, label, onClickHandler}: { direction: 'desc' | 'asc' | null, label: string, onClickHandler: () => void }) {
    const classes = useStyles();
    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            onClick={onClickHandler}
        >
            <Grid item>
                {label}
            </Grid>
            <Grid
                item
                xs
                container
                direction="column"
            >
                <KeyboardArrowUp className={classes.control} color={'asc' === direction ? 'action' : 'disabled'}/>
                <KeyboardArrowDown className={classes.control} color={'desc' === direction ? 'action' : 'disabled'}/>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        control: {
            cursor: 'pointer'

        }
    }));
