import React from "react";
import Layout from "./base/Layout";
import LoadingComponent from "./LoadingComponent";
import {RootState} from "../reducers";
import {AppStateMessage} from "../entities/AppStateMessage";
import { useSelector } from 'react-redux'
import {Chip, createStyles, Grid, makeStyles, Theme} from '@material-ui/core'
import {Error, DoneAll} from "@material-ui/icons";
import {store} from "../";
import {removeMessageAction} from "../reducers/appState";

export default ({children}: { children: React.ReactNode}) => {
    const isLoading = useSelector((state: RootState) => state.appState.isLoading);
    const messages = useSelector((state: RootState) => state.appState.messages);
    const classes = useStyles();
    return (
        <Layout>
            <Grid
                className={classes.container}
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
            >
                {messages.map((message: AppStateMessage, key) =>  <Notifications key={key} message={message} />)}
            </Grid>
            {isLoading && (
                <LoadingComponent/>
            )}
            {children}
        </Layout>
    );
}

const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        container: {
            position: "absolute",
            right: 10,
            zIndex: 1
        },
        message: {
            marginBottom:10
        }
    }));

const Notifications = ({message}: {message: AppStateMessage}) => {
    const classes = useStyles();
    const handleDelete = () => {store.dispatch(removeMessageAction(message))};

    return (
        <Chip
            className={classes.message}
            icon={message.type === 'error' ? <Error/> : <DoneAll/>}
            label={message.text}
            color={message.type === 'error' ? "secondary" : "primary"}
            onDelete={handleDelete}
    />
    );
}