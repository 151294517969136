import {Filter} from "../entities/Filter";
import {SelectedFilter} from "../entities/SelectedFilter";
import {store} from "../index";
import {setReportSelectFilterAction, removeReportSelectFilterAction, setPageAction} from "../reducers/report";
import React from "react";
import {useDispatch} from "react-redux";
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Chip} from "@material-ui/core";
import {loadReport} from "../actions/report";
import {FilterValue} from "../entities/FilterValue";

export default ({filter}: { filter: Filter }) => {
    const dispatch = useDispatch();

    const selectedFilters: SelectedFilter[] = store.getState().report.selectedFilters;

    let filtredSelectedFilters: SelectedFilter[] = selectedFilters.filter((item: SelectedFilter) => filter.alias === item.filter.alias);

    const filterValues = undefined !== filter.filterValues ? filter.filterValues : [];

    const getTitleById = (id: number): string|undefined => {
        let title = undefined;
        filterValues.forEach((filterValue: FilterValue) => {
            if (filterValue.id === id) {
                title = filterValue.title;
                return true;
            }
        });

        return title;
    }

    const checkSelected = (id: number) => {
        let selected = false;
        filtredSelectedFilters.forEach((item: SelectedFilter) => {
            if (id === item.value) {
                selected = true;
            }
        });

        return selected;
    };

    const handleChange = (id: number) => {
        if (checkSelected(id)) {
            filtredSelectedFilters.forEach((item: SelectedFilter) => {
                if (id === item.value) {
                    dispatch(removeReportSelectFilterAction(item));
                }
            });

        } else {
            dispatch(setReportSelectFilterAction(new SelectedFilter(filter, id)));
        }

        dispatch(setPageAction(0));
        dispatch(loadReport());
    };

    return (
        <FormControl>
            <InputLabel shrink>
                {filter.label}
            </InputLabel>
            <Select
                multiple
                value={filtredSelectedFilters.map((filtredSelectedFilter: SelectedFilter) => filtredSelectedFilter.value)}
                renderValue={selected => (selected as string[]).join(', ')}
            >
                {filterValues.map((filterValue: FilterValue) => (
                    <MenuItem key={filterValue.id} value={filterValue.id}>
                        <Checkbox checked={checkSelected(filterValue.id)} onClick={() => handleChange(filterValue.id)}/>
                        <ListItemText primary={filterValue.title} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
)
};
