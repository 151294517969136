import {connect} from "react-redux";
import ReportActionComponent from "../components/ReportActionComponent";
import {RootState} from "../reducers";

const mapDispatchToProps = (dispatch: any) => {

    return {

    };
};

const mapStateToProps = (state: RootState) => {
    return {

    }
};

const ReportActionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(ReportActionComponent);

export default ReportActionContainer