import {connect} from 'react-redux'
import ReportComponent from '../components/ReportComponent'
import React, { Component } from 'react';
import {initReport} from "../actions/report";

class Report extends Component<{match: any, init: (alias: string) => void}, {}> {
    load(alias: string){
        switch (alias) {
            case 'user':
                this.props.init('REPORT_USER');
                break;
            case 'quiz':
                this.props.init('REPORT_QUIZ');
                break;
            case 'payment':
                this.props.init('REPORT_PAYMENT');
                break;
            case 'lead':
                this.props.init('REPORT_LEAD');
                break;
            case 'action':
                this.props.init('REPORT_USER_ACTION');
                break;
            case 'session':
                this.props.init('REPORT_SESSION');
                break;
            default:
                //404
                break;
        }
    }

    componentDidMount() {
        this.load(this.props.match.params.alias)
    }

    componentDidUpdate(prevProps: {match: any}) {
        if(this.props.match.params.alias !== prevProps.match.params.alias){
            this.load(this.props.match.params.alias)
        }
    }

    render() {
        return <ReportComponent/>
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        init: (alias: string) => {
            dispatch(initReport(alias));
        }
    };
};

const ReportContainer = connect(
    null,
    mapDispatchToProps
)(Report);

export default ReportContainer

