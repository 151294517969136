import {requestApiWithToken, RequestOptionsInterface} from './requestApi';
import  {Moment} from 'moment';
export const getMetric = (alias: string, dateFrom?: Moment, dateTo?: Moment) => {
    const requestOptions: RequestOptionsInterface = {
        method: 'POST',
        body: JSON.stringify({
            dateFrom:  undefined !== dateFrom ? dateFrom.format('Y-MM-DD HH:mm:SS'): null,
            dateTo: undefined !== dateTo ? dateTo.format('Y-MM-DD HH:mm:SS') : null
        })
    };
    return requestApiWithToken(`metric/${alias}`, requestOptions);
};


export  interface MetricResponseInterface {
    data: {value: number},
    meta: {
    }
}
