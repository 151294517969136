import React from 'react';
import {Router, Route, Redirect} from "react-router-dom";
import ReportContainer from "./containers/ReportContainer";
import UserItemContainer from "./containers/UserItemContainer";
import DashboardContainer from "./containers/DashboardContainer";
import LoginContainer from "./containers/LoginContainer";
import history from "./services/historyService";
import Layout from './components/Layout';
import {store} from "./index";

function App() {
    return (
        <Router history={history}>
            <PublicRoute exact path="/login" component={LoginContainer}/>
            <PrivateRoute exact path="/report/:alias" component={ReportContainer}/>
            <PrivateRoute exact path="/report/user/:id" component={UserItemContainer}/>
            <PrivateRoute exact path="/dashboard" component={DashboardContainer}/>
            <Route exact path="/" render={() => <Redirect to="/dashboard"/>}/>
            {/*<PrivateRoute  path="/" component={() => <p>Не найдена страница</p>}/>*/}
        </Router>
    );

    function PrivateRoute({component, ...rest}: any) {
        return (
            <Route
                {...rest}
                render={props =>
                    store.getState().user !== null ? (
                        <Layout>{React.createElement(component, props)}</Layout>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    )
                }
            />
        );
    }

    function PublicRoute({component, ...rest}: any) {
        return (
            <Route
                {...rest}
                render={props =>
                    store.getState().user !== null ? (
                        <Redirect
                            to={{
                                pathname: "/dashboard",
                            }}
                        />
                    ) : (
                        React.createElement(component, props)
                    )
                }
            />
        );
    }
}

export default App;



