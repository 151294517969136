import React from 'react';
import {Column} from '../entities/Column';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from './TableSortLabel'

export interface TableHeadComponentProps  {clickCheckboxHandler: () => void, checked: boolean, indeterminate: boolean, withChecked: boolean, columns: Column[], clickSortLabelCallback: (parentColumnId: string) => void  };

export const TableHeadComponent = function ({clickCheckboxHandler, checked, indeterminate,  withChecked, columns, clickSortLabelCallback}: TableHeadComponentProps) {
    return (
        <TableHead>
            <TableRow>
                {withChecked &&
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={clickCheckboxHandler}
                        checked={checked}
                    />
                </TableCell>
                }
                {columns.map((column: Column) => (
                    <TableCell key={column.id} style={{ whiteSpace: 'pre-wrap'}}>
                        {undefined !== column.order ?
                            <TableSortLabel
                                onClickHandler={() => clickSortLabelCallback(column.order!.parentColumnId)}
                                direction={column.order.direction}
                                label={column.label}
                            /> : column.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}