import {
    setColumnsAction,
    setPageAction,
    setRowsAction,
    setRowsPerPageAction,
    setReportAliasAction,
    changeColumnOrderDirectionAction,
    setReportFiltersAction,
    setReportSelectFilterArrayAction
} from '../reducers/report';
import {ThunkAction,} from 'redux-thunk'
import {Action} from 'redux';
import {RootState} from "../reducers";
import {getReport} from "../api/report";
import {Column} from "../entities/Column";
import {Row} from "../entities/Row";

import {
    ReportResponseDataInterface,
    createColumnsByData,
    createRowsByData,
    createFiltersByData,
    createSelectedFiltersByData
} from '../services/reportService'
import {setIsLoadingAction, setMessageAction} from "../reducers/appState";
import {store} from "../index";
import {AppStateMessage} from "../entities/AppStateMessage";


export const loadReport = (): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setIsLoadingAction(true));

    const report = store.getState().report;
    let orderBy = null;
    let orderDirection = null;
    report.columns.forEach((column: Column) => {
        if(undefined !== column.order && null !== column.order.direction){
            orderBy = column.id;
            orderDirection = column.order.direction;
        }
    });

    const params = {
        rowsPerPage: report.rowsPerPage,
        page: report.page,
        orderBy: orderBy,
        orderDirection: orderDirection,
        selectedFilters: report.selectedFilters,
        reportAlias: report.reportAlias
    };

    getReport(params).then((dataReport: ReportResponseDataInterface) => {
        const columns: Column[] = createColumnsByData(dataReport.meta.columns);
        const rows: Row[] = createRowsByData(dataReport.data);
        dispatch(setColumnsAction(columns));
        dispatch(setPageAction(dataReport.meta.page));
        dispatch(setRowsPerPageAction(dataReport.meta.rowsPerPage));
        dispatch(setRowsAction(rows, dataReport.meta.total));


        dispatch(setReportSelectFilterArrayAction(createSelectedFiltersByData(dataReport.meta.selectedFilters)));
        dispatch(setReportFiltersAction(createFiltersByData(dataReport.meta.filters)));
        dispatch(setIsLoadingAction(false));
    }).catch((e) => {
        store.dispatch(setMessageAction(new AppStateMessage(e, 'error')));
        store.dispatch(setIsLoadingAction(false));
    });
};

export const initReport = (
    reportAlias: string
): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setReportAliasAction(reportAlias));
    dispatch(loadReport());
};

export const updateRowsPerPage = (
    rowsPerPage: number
): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setPageAction(0));
    dispatch(setRowsPerPageAction(rowsPerPage));
    dispatch(loadReport());
};

export const updatePagePagination = (
    newPage: number
): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setPageAction(newPage));
    dispatch(loadReport());
};

export const changeColumnOrderDirection = (
    parentColumnId: string
): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setPageAction(0));
    dispatch(changeColumnOrderDirectionAction(parentColumnId));
    dispatch(loadReport());
};

export const resetFilter = (
): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setPageAction(0));
    dispatch(setReportSelectFilterArrayAction([]));
    dispatch(loadReport());
};