import React, {useState} from 'react';

import {
    Theme,
    createStyles,
    makeStyles,
    TextField,
    FormControl,
    Button, Grid, CircularProgress,
    Chip
} from "@material-ui/core";
import {Face as FaceIcon} from "@material-ui/icons";
import {AppStateMessage} from "../entities/AppStateMessage";

export default ({loginHandler, isLoading, messages}: { loginHandler: (email: string, password: string) => void, isLoading: boolean, messages: AppStateMessage[]}) => {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const submitHandler = () => {
        return loginHandler(email, password)
    };


    return (
        <Grid container className={classes.container}>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <FormControl>
                    <TextField
                        fullWidth
                        name="email"
                        value={email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(event.target.value)
                        }} label="email"
                    />
                    <TextField
                        fullWidth
                        name="password"
                        type="password"
                        value={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value)
                        }}
                        label="password"
                    />
                    <div className={classes.formButtons}>
                        {isLoading ? (
                            <CircularProgress size={26}/>
                        ) : (<Button
                            color="primary"
                            onClick={submitHandler}
                        >OK</Button>)}
                    </div>
                    {messages.map((message: AppStateMessage, key: number) => {
                        return (
                            <Chip
                                key={key}
                                icon={<FaceIcon/>}
                                label={message.text}
                                color="secondary"
                            />)
                    })}
                    </FormControl>
                </div>
            </div>
        </Grid>
    );
}

const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        container: {
            height: "100vh",
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
        },
        formContainer: {
            width: "40%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.down("md")]: {
                width: "50%",
            },
        },
        form: {
            width: 320,
        },
        formButtons: {
            width: "100%",
            marginTop: theme.spacing(4),
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        }
    }));

