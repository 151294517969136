import { combineReducers } from 'redux'
import report from './report'
import metrics from './metrics'
import user from './user'
import appState from './appState'

export const rootReducer = combineReducers({
    report: report,
    metrics: metrics,
    user: user,
    appState: appState
});

export type RootState = ReturnType<typeof rootReducer>
