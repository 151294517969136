import {connect} from "react-redux";
import PayedToPartnerComponent from "../components/PayedToPartnerComponent";
import {RootState} from "../reducers";
import {ThunkDispatch} from "redux-thunk";
import {payedToPartner} from '../api/reportActions';
import {store} from "../index";
import {setIsLoadingAction, setMessageAction} from "../reducers/appState";
import {AppStateMessage} from "../entities/AppStateMessage";
import {loadReport} from "../actions/report";

const mapStateToProps = (state: RootState) => {
    let checkedRows = 0;
    if(state.report.checked.all){
        checkedRows = state.report.totalRows - state.report.checked.without.length
    }else{
        checkedRows = state.report.checked.with.length;
    }

    return {
        checkedRows: checkedRows
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: {closeModalHandler: () => void} ) => {
    return {
        sendHandler: () => {

            dispatch(setIsLoadingAction(true));
            payedToPartner().then(() => {
                ownProps.closeModalHandler();
                dispatch(setMessageAction(new AppStateMessage('ok', 'success')));
                dispatch(setIsLoadingAction(false));
                dispatch(loadReport());
            }).catch((e) => {
                dispatch(setMessageAction(new AppStateMessage(e, 'error')));
                dispatch(setIsLoadingAction(false));
            });


        }
    }
};
const PayedToPartnerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(PayedToPartnerComponent);

export default PayedToPartnerContainer


