import {Filter} from "../entities/Filter";
import {SelectedFilter} from "../entities/SelectedFilter";
import {store} from "../index";
import {setReportSelectFilterAction, removeReportSelectFilterAction, setPageAction} from "../reducers/report";
import React from "react";
import {useDispatch} from "react-redux";
import { Checkbox, FormControlLabel} from "@material-ui/core";
import {loadReport} from "../actions/report";

export default ({filter}: { filter: Filter }) => {
    const dispatch = useDispatch();
    const selectedFilters: SelectedFilter[] = store.getState().report.selectedFilters;
    let selectedFilter: SelectedFilter|null = null;
    selectedFilters.forEach((item: SelectedFilter) => {
        if (filter.alias === item.filter.alias) {
            selectedFilter = item;
        }
    });
    const checked = (null !== selectedFilter);

    const handleChange = () => {
        if(null === selectedFilter){
            dispatch(setReportSelectFilterAction(new SelectedFilter(filter, true)));
        }else{
            dispatch(removeReportSelectFilterAction(selectedFilter));
        }
        dispatch(setPageAction(0));
        dispatch(loadReport());
    };

    return (
        <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange}/>}
            label={filter.label}
        />
    )
};
