import React from 'react';
import {TableBody} from "@material-ui/core";
import {Row} from "../entities/Row";
import TableRowContainer from "../containers/TableRowContainer";

export interface TableBodyComponentProps {rows: Row[]};

export const TableBodyComponent = function TableBodyComponent({rows}: TableBodyComponentProps) {
    return (
        <TableBody>
            {rows.map((row: Row) => <TableRowContainer key={row.id} row={row}/>)}
        </TableBody>
    );
}