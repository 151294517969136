import {connect} from "react-redux";
import SendTelegramComponent from "../components/SendTelegramComponent";
import {RootState} from "../reducers";
import {ThunkDispatch} from "redux-thunk";
import {sendTelegram} from '../api/reportActions';
import {store} from "../index";
import {setIsLoadingAction, setMessageAction} from "../reducers/appState";
import {AppStateMessage} from "../entities/AppStateMessage";

const mapStateToProps = (state: RootState) => {
    let checkedRows = 0;
    if(state.report.checked.all){
        checkedRows = state.report.totalRows - state.report.checked.without.length
    }else{
        checkedRows = state.report.checked.with.length;
    }

    return {
        checkedRows: checkedRows
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>, ownProps: {closeModalHandler: () => void} ) => {
    return {
        sendHandler: (text: string, alias: string) => {
            const data = {
                text: text,
                alias: alias,
            };

            store.dispatch(setIsLoadingAction(true));
            sendTelegram(data).then(() => {
                ownProps.closeModalHandler();
                store.dispatch(setMessageAction(new AppStateMessage('Сообщения отправлены', 'success')));
                store.dispatch(setIsLoadingAction(false));
            }).catch((e) => {
                store.dispatch(setMessageAction(new AppStateMessage(e, 'error')));
                store.dispatch(setIsLoadingAction(false));
            });


        }
    }
};
const SendTelegramContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(SendTelegramComponent);

export default SendTelegramContainer


