import {connect} from "react-redux";
import {TableBodyComponent} from "../components/TableBodyComponent";
import {RootState} from "../reducers";

const mapStateToProps = (state: RootState) => {
    return {
        rows:  state.report.rows,
        columns: state.report.columns
    }
};

const TableBodyContainer = connect(
    mapStateToProps
)(TableBodyComponent);

export default TableBodyContainer


