import React from 'react';
import TablePagination from "@material-ui/core/TablePagination/TablePagination";

export default function TablePaginationComponent({totalRows, rowsPerPage, page, changeRowsPerPageCallback, changePageCallback}
: { totalRows: number, rowsPerPage: number, page: number, changeRowsPerPageCallback: (rowsPerPage: number) => void, changePageCallback: (newPage: number) => void }) {

    const handleChangePage = (event: unknown, newPage: number) => {
        changePageCallback(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeRowsPerPageCallback(+event.target.value);
    };

    return (
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );
}