import {connect} from "react-redux";
import {TableHeadComponent} from "../components/TableHeadComponent";
import {RootState} from "../reducers";
import {ThunkDispatch} from "redux-thunk";
import {changeColumnOrderDirection} from "../actions/report";
import {getActionsByReportAlias} from '../services/reportService'
import {reportChangeCheckedAllAction, reportRemoveCheckedWithAction, reportRemoveCheckedWithoutAction} from "../reducers/report";
import {store} from '../index';

const mapStateToProps = (state: RootState) => {
    return {
        rows:  state.report.rows,
        columns: state.report.columns,
        withChecked: getActionsByReportAlias(state.report.reportAlias).length > 0,
        checked: state.report.checked.all,
        indeterminate: state.report.checked.without.length > 0 || state.report.checked.with.length > 0
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
        clickSortLabelCallback: (columnId: string) => {
            dispatch(changeColumnOrderDirection(columnId))
        },
        clickCheckboxHandler: () => {
            const report = store.getState().report;
            if(report.checked.without.length === 0 && report.checked.with.length === 0){
                dispatch(reportChangeCheckedAllAction());
            }else if(report.checked.without.length > 0){
                report.checked.without.forEach((item) =>  dispatch(reportRemoveCheckedWithoutAction(item)));
            }else if(report.checked.with.length > 0){
                report.checked.with.forEach((item) =>  dispatch(reportRemoveCheckedWithAction(item)));
                dispatch(reportChangeCheckedAllAction());
            }
        }
    }
};

const TableHeadContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TableHeadComponent);

export default TableHeadContainer