export class User {
    public login: string;
    public token: string;
    public name?: string;

    constructor(
        login: string,
        token: string,
        name?: string
    ) {
        this.login = login;
        this.token = token;
        this.name = name;
    }
}