import {connect} from "react-redux";
import HeaderComponent from "../components/base/Header";
import {logoutUser} from '../actions/user';
import {RootState} from "../reducers";

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            logoutUser();
        }
    };
};

const mapStateToProps = (state: RootState) => {
    const user = state.user;
    return {
        login: null !== user ? user.login : null,
        name: null !== user ? user.name : null,

    }
};

const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderComponent);

export default HeaderContainer