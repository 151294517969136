import React, {useState} from 'react';
import {makeStyles, Grid, FormControl, TextField, Button, Theme, createStyles} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 15
        }
    }));

export default function SendTelegramComponent({sendHandler, checkedRows}: { sendHandler: (text: string, alias: string) => void, checkedRows: number }) {
    const classes = useStyles();

    const [text, setText] = useState('');
    const [alias, setAlias] = useState('');


    const handleChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleChangeAlias = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAlias(event.target.value);
    };

    const buttonClickHandler = () => {
        sendHandler(text, alias);
    };

    return (
        <Grid className={classes.container}>
            <p>{checkedRows} адресатов</p>
            <FormControl>
                <TextField onChange={handleChangeAlias} value={alias} label="alias"/>
                <TextField rows={4} onChange={handleChangeText} value={text} multiline label=" Текст сообщения"/>
                <Button onClick={buttonClickHandler}>отправить</Button>
            </FormControl>
        </Grid>
    );
}