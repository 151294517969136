import {Metric} from "../entities/Metric";

export const findMetricByAlias = (alias: string, metrics: Metric[]): Metric|null => {
    let metric: Metric|null = null;
    metrics.forEach((item) => {
        if(item.alias === alias){
            metric = item;
        }
    });
    return metric;
};

