import {requestApi, RequestOptionsInterface} from './requestApi';

export const login = (login: string, password: string) => {
    const requestOptions: RequestOptionsInterface = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ email:login, password:password })
    };
    return requestApi(`login`, requestOptions);
};

export  interface LoginResponseInterface {
    token: string
}
