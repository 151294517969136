import {requestApiWithToken, RequestOptionsInterface} from './requestApi';
import {SelectedFilter} from "../entities/SelectedFilter";

export interface GetReportParams  {
    rowsPerPage: number,
    page: number,
    orderBy: null | string,
    orderDirection: null | string,
    selectedFilters: SelectedFilter[],
    reportAlias: string|null
};

export const getReport = (params: GetReportParams) => {
    const requestOptions: RequestOptionsInterface = {
        method: 'POST',
        body: JSON.stringify({
            rowsPerPage: params.rowsPerPage,
            page: params.page,
            orderBy: params.orderBy,
            orderDirection: params.orderDirection,
            selectedFilters: params.selectedFilters
        })
    };
    return requestApiWithToken(`report/${params.reportAlias}`, requestOptions);
};
