import {CircularProgress, Grid} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import React from "react";

export default () => {
    const classes = useStyles();

    return (
        <Grid
            className={classes.root}
            container
            justify="center"
            alignItems="center"
        >
            <CircularProgress/>
        </Grid>
    );
}

const useStyles = makeStyles({
    root: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        background: '#1976d221',
        zIndex: 9
    }
});