import {FilterGroup} from "../entities/FilterGroup";
import {Filter} from "../entities/Filter";
import React from "react";
import {createStyles, makeStyles, Theme, FormLabel, FormGroup} from '@material-ui/core';
import FilterDateContainer from './FilterDateContainer';
import FilterSearchContainer from './FilterSearchContainer';
import FilterCheckboxContainer from './FilterCheckboxContainer';
import FilterRadioContainer from './FilterRadioContainer';
import FilterMultiselectContainer from './FilterMultiselectContainer';

export default ({filterGroup}: { filterGroup: FilterGroup }) => {
    const classes = useStyles();
    return (<div key={filterGroup.group} className={classes.container}>
            <FormLabel>{filterGroup.group}</FormLabel>
            <FormGroup>
                {filterGroup.items.map((filter: Filter) => {
                    switch (filter.type) {
                        case 'search':
                            return <FilterSearchContainer key={filter.alias} filter={filter}/>;
                        case 'date':
                            return <FilterDateContainer key={filter.alias} filter={filter}/>;
                        case 'checkbox':
                            return <FilterCheckboxContainer key={filter.alias} filter={filter}/>;
                        case 'radio':
                            return <FilterRadioContainer key={filter.alias} filter={filter}
                                                         filterGroupItems={filterGroup.items}/>;
                        case 'multiselect':
                            return <FilterMultiselectContainer key={filter.alias} filter={filter}
                                                         />;
                        default:
                            return null;
                    }
                })}
            </FormGroup>
            <br/>
            <br/>
        </div>
    )
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginBottom: theme.spacing(1),
        }
    }));
















