import {AppStateMessage} from '../entities/AppStateMessage';

const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_MESSAGE = 'SET_MESSAGE';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
const REMOVE_ALL_MESSAGE = 'REMOVE_ALL_MESSAGE';

const appState = (state: {isLoading: boolean, messages: AppStateMessage[]} = {isLoading: false, messages: []}, action: AppStateActionTypes) => {
    switch (action.type) {
        case SET_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case SET_MESSAGE:
            return {...state, messages: [...state.messages, action.message]};
        case REMOVE_MESSAGE:
            return {...state, messages: state.messages.filter((item: AppStateMessage) => item !== action.message)};
        case REMOVE_ALL_MESSAGE:
            return {...state, messages: []};
        default:
            return state;
    }
};

export default appState;

interface SetIsLoadingAction {
    type: typeof SET_IS_LOADING,
    isLoading: boolean
}

export function setIsLoadingAction(isLoading: boolean): SetIsLoadingAction {
    return {
        type: SET_IS_LOADING,
        isLoading: isLoading
    }
}

interface SetMessageAction {
    type: typeof SET_MESSAGE,
    message: AppStateMessage
}

export function setMessageAction(message: AppStateMessage): SetMessageAction {
    return {
        type: SET_MESSAGE,
        message: message
    }
}

interface RemoveMessageAction {
    type: typeof REMOVE_MESSAGE,
    message: AppStateMessage
}

export function removeMessageAction(message: AppStateMessage): RemoveMessageAction {
    return {
        type: REMOVE_MESSAGE,
        message: message
    }
}

interface RemoveAllMessageAction {
    type: typeof REMOVE_ALL_MESSAGE
}

export function removeAllMessageAction(): RemoveAllMessageAction {
    return {
        type: REMOVE_ALL_MESSAGE
    }
}


export type AppStateActionTypes = SetIsLoadingAction|SetMessageAction|RemoveMessageAction|RemoveAllMessageAction;