import React from 'react';
import {Column} from "../entities/Column";
import {OrderColumn} from "../entities/OrderColumn";
import {Row} from "../entities/Row";
import {ColumnDataIntarface} from '../entities/import/ColumnDataIntarface';
import {Person, Phone, Language, QuestionAnswer, DateRange, Sms, Email, Telegram, DoneAll, Error, Timer, Payment} from "@material-ui/icons";
import {FilterGroup} from "../entities/FilterGroup";
import {Filter, filterType} from "../entities/Filter";
import {SelectedFilter} from "../entities/SelectedFilter";
import {ReportAction} from '../entities/ReportAction';
import SendEmailContainer from '../containers/SendEmailContainer';
import SendTelegramContainer from '../containers/SendTelegramContainer';
import PayedToPartnerContainer  from '../containers/PayedToPartnerContainer';

import moment from "moment";
import {FilterValue} from "../entities/FilterValue";

export const createColumnsByData = (columnsData: ColumnDataIntarface[]): Column[] => {
    const columns: Column[] = [];
    columnsData.forEach((columnData: ColumnDataIntarface) => {
        const column = new Column(
            columnData.id,
            columnData.label,
            (true === columnData.isOrderable) ? new OrderColumn(columnData.orderDirection, columnData.id) : undefined,
        );
        columns.push(column);
    });
    return columns;
};

export const createRowsByData = (rowsData: object[]): Row[] => {
    const rows: Row[] = [];
    rowsData.forEach((rowData: { [index: string]: any }) => {
        const rowId = rowData['id'][0].value;
        const row = new Row(
            rowData,
            rowId
        );
        rows.push(row);
    });
    return rows;
};


export const createSelectedFiltersByData = (selectedFiltersData: SelectedFilterResponseDataInterface[]): SelectedFilter[] => {
    const selectedFilters: SelectedFilter[] = [];
    selectedFiltersData.forEach((selectedFilterData: SelectedFilterResponseDataInterface) => {
        const selectedFilter = new SelectedFilter(
            createFilterByData(selectedFilterData.filter),
           'date' === selectedFilterData.filter.type ? moment(selectedFilterData.value).format('Y-MM-DD') : selectedFilterData.value
        );
        selectedFilters.push(selectedFilter);
    });
    return selectedFilters;
};

export const createFiltersByData = (filtersData: FilterGroupResponseDataInterface[]): FilterGroup[] => {
    const filterGroups: FilterGroup[] = [];
    filtersData.forEach((filterData: FilterGroupResponseDataInterface) => {
        const filterGroup = new FilterGroup(
            filterData.group,
            filterData.items.map((filterData: FilterResponseDataInterface) => createFilterByData(filterData))
        );
        filterGroups.push(filterGroup);
    });
    return filterGroups;
};

export const createFilterByData = (filterData: FilterResponseDataInterface): Filter => {
    return new Filter(
        filterData.label,
        filterData.type,
        filterData.alias,
        filterData.fullLabel,
        filterData.filterValues
    );
};

interface FilterResponseDataInterface {
    label: string,
    type: filterType,
    alias: string,
    fullLabel: string | null,
    filterValues?: FilterValue[]
}

interface FilterGroupResponseDataInterface {
    group: string,
    items: FilterResponseDataInterface[]
}

interface SelectedFilterResponseDataInterface {
    value: any,
    filter: FilterResponseDataInterface
}

export interface ReportResponseDataInterface {
    data: [],
    meta: {
        columns: [],
        total: number,
        page: number,
        rowsPerPage: number,
        filters: FilterGroupResponseDataInterface[],
        selectedFilters: SelectedFilterResponseDataInterface[]
    }
}

export const getStyleToCell = (alias: string) => {
    return (undefined !== styleToCell[alias]) ? styleToCell[alias] : {color: undefined, icon: undefined};
}

const styleToCell: any = {
    deleted: {
        icon: undefined,
        color: 'secondary',
        title: 'удален'
    },
    name: {
        icon: <Person/>,
        color: undefined
    },
    phone: {
        icon: <Phone/>,
        color: undefined
    },
    isTest: {
        icon: undefined,
        color: 'primary',
        title: 'тест'
    },
    site: {
        icon: <Language/>,
        color: undefined
    },
    date: {
        icon: <DateRange/>,
        color: undefined
    },
    leadCount: {
        icon: <QuestionAnswer/>,
        color: undefined
    },
    noPayment: {
        icon: undefined,
        color: 'secondary',
        title: 'не оплачен'
    },
    telegram: {
        icon: <Telegram/>,
        color: undefined
    },
    sms: {
        icon: <Sms/>,
        color: undefined
    },
    email: {
        icon: <Email/>,
        color: undefined
    },
    success: {
        icon: <DoneAll/>,
        color: undefined
    },
    error: {
        icon: <Error/>,
        color: undefined
    },
    processed: {
        icon: <Timer/>,
        color: undefined
    },
    payed: {
        icon: <Payment/>,
        color: undefined
    }
}

export const getActionsByReportAlias = (alias: string|null): ReportAction[] => {
    switch (alias) {
        case 'REPORT_USER':
            return [
                new ReportAction('email', 'send email', <Email/>, SendEmailContainer),
                new ReportAction('telegram', 'send telegram', <Telegram/>, SendTelegramContainer)
            ];
        case 'REPORT_PAYMENT':
            return [
                new ReportAction('payed', 'payed to partner', <Payment/>, PayedToPartnerContainer),
            ];
        default:
            return [];
    }
};
