import React from 'react';
import {Column} from '../entities/Column';
import {Row} from '../entities/Row';
import {
    TableRow,
    TableCell,
    Chip,
    makeStyles,
    Theme,
    createStyles,
    Checkbox,
    Typography,
    Popover,
} from "@material-ui/core";
import {getStyleToCell} from '../services/reportService';
import moment from 'moment';
import {Link} from "react-router-dom";

export default function TableRowComponent({link, clickCheckboxHandler, checked, withChecked, row, columns}: {link?: string, clickCheckboxHandler: () => void, checked: boolean, withChecked: boolean, row: Row, columns: Column[] }) {
    const classes = useStyles();


    return (

        <TableRow hover role="checkbox" tabIndex={-1}>
            {withChecked &&
            <TableCell
                padding="checkbox"
            >
                <Checkbox
                    onChange={clickCheckboxHandler}
                    checked={checked}
                />
            </TableCell>
            }
            {columns.map((column: Column) => {
                const rowItems: { [index: string]: any } = row.values;
                const values = rowItems[column.id];
                return (
                    <TableCell className={classes.root} key={column.id}>
                        {values.map((item: any, valueKey: any) => {
                            let ValueComponent;

                            if (item.type === 'date' && null !== item.value) {
                                ValueComponent = () => moment(item.value).format('DD.MM.Y HH:mm');
                            } else {
                                if (null !== item.value && item.value.length >= 50) {
                                    ValueComponent = () => {
                                        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

                                        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                                            setAnchorEl(event.currentTarget);
                                        };

                                        const handleClose = () => {
                                            setAnchorEl(null);
                                        };

                                        const open = Boolean(anchorEl);
                                        const id = open ? 'simple-popover' : undefined;

                                        return (
                                            <>
                                                <span
                                                    className={classes.popover}
                                                    aria-describedby={id}
                                                    onClick={handleClick}
                                                >
                                                    {`${item.value.substr(0, 40)}...`}
                                                </span>
                                                <Popover
                                                    id={id}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <Typography variant="body2">{item.value}</Typography>
                                                </Popover>
                                            </>);
                                    }
                                } else {
                                    ValueComponent = () => item.value;
                                }
                            }

                        if (null === item.title && undefined === item.alias) {
                        return <div key={valueKey} children={<ValueComponent/>}/>;
                    }

                        const color = getStyleToCell(item.title).color;
                        const icon = getStyleToCell(item.title).icon;

                        const title = undefined === icon ? `${item.title}: ` : '';
                        let value = getStyleToCell(item.title).title ? getStyleToCell(item.title).title :

                            <Typography
                        variant="caption">{title}<ValueComponent/></Typography>;

                                if ('id' === item.title && undefined !== link){
                                    value =  <Link to={link}>{item.value}</Link>;
                                }


                        return <div key={valueKey}><Chip color={color} icon={icon}
                                                         size="small" label={value}/><br/></div>

                        }
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}


const useStyles = makeStyles((theme: Theme) =>

    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(0.5),
            }
        },
        popover: {
            cursor: 'pointer'
        }
    }));

