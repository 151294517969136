import {OrderColumn} from './OrderColumn';

export class Column {
    public id: string;
    public label: string;
    public order?: OrderColumn;

    constructor(
        id: string,
        label: string,
        order?: OrderColumn
    ) {
        this.id = id;
        this.label = label;
        if (undefined !== order) {
            this.order = order;
        }
    }
}


