import React from 'react';
import BaseWidget from "../components/base/Widget";
import {Typography} from "../components/base/Wrappers";
import {createStyles, Grid, makeStyles, Theme} from "@material-ui/core";

const Widget = ({title, mainValue, values}: { title: string, mainValue: number, values: { title: string, value: number }[] }) => {
    const classes = useStyles();
    return (
        <BaseWidget
            title={title}
            upperTitle={null}
            disableWidgetMenu={true}
            noBodyPadding={null}
            bodyClass={null}
            header={null}
        >
            <div className={classes.numberContainer}>
                <Typography size="xl" weight="medium" color={null} colorBrightness={null}>
                    {mainValue}
                </Typography>
            </div>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                {values.map((item, index) => (
                    <Grid item key={index}>
                        <Typography size={null} weight={null} color="text" colorBrightness="secondary">
                            {item.title}
                        </Typography>
                        <Typography color={null} colorBrightness={null} size="md"
                                    weight={null}>{item.value}</Typography>
                    </Grid>
                ))}
            </Grid>

        </BaseWidget>);
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        numberContainer: {
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            paddingBottom: theme.spacing(1),
        }
    }));

export default Widget;