import {setMetricAction} from '../reducers/metrics';
import { ThunkAction,  } from 'redux-thunk'
import { Action } from 'redux';
import {RootState} from "../reducers";
import {Metric} from "../entities/Metric";
import {getMetric, MetricResponseInterface} from "../api/metrics";
import  {Moment} from 'moment';
import {setIsLoadingAction, setMessageAction} from "../reducers/appState";
import {store} from "../index";
import {AppStateMessage} from "../entities/AppStateMessage";

export const loadMetric = (metricName: string, alias: string, dateFrom?: Moment, dateTo?: Moment): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setIsLoadingAction(true));
    getMetric(metricName, dateFrom, dateTo).then((metricResponse: MetricResponseInterface) => {
        const metric: Metric = new Metric(metricResponse.data.value, alias);
        dispatch(setMetricAction(metric));
        store.dispatch(setIsLoadingAction(false));
    }).catch((e) => {
        store.dispatch(setMessageAction(new AppStateMessage(e, 'error')));
        store.dispatch(setIsLoadingAction(false));
    });

};
