export class Row {
    public values: {};
    public id: number;

    constructor(
        values: {},
        id: number
    ) {
        this.values = values;
        this.id = id;
    }
}


