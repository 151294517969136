import {connect} from "react-redux";
import LoginComponent from "../components/LoginComponent";
import {loginUser} from "../actions/user";
import {RootState} from "../reducers";

const mapDispatchToProps = (dispatch: any) => {

    return {
        loginHandler: (login: string, password: string) => {
            dispatch(loginUser(login,password));
        }
    };
};

const mapStateToProps = (state: RootState) => {
    return {
        isLoading: state.appState.isLoading,
        messages: state.appState.messages
    }
};

const LoginContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginComponent);

export default LoginContainer