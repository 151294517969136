import {Filter} from "../entities/Filter";
import {SelectedFilter} from "../entities/SelectedFilter";
import {store} from "../index";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {setReportSelectFilterAction, removeReportSelectFilterAction, setPageAction} from "../reducers/report";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import {useDispatch} from "react-redux";
import {IconButton, Grid, createStyles, makeStyles, Theme} from "@material-ui/core";
import {Delete} from "@material-ui/icons";
import {loadReport} from "../actions/report";
import  moment from 'moment';

export default ({filter}: { filter: Filter }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const selectedFilters: SelectedFilter[] = store.getState().report.selectedFilters;
    let selectedFilter: SelectedFilter | null = null;
    let selectedDate = null;

    selectedFilters.forEach((item: SelectedFilter) => {
        if (filter.alias === item.filter.alias) {
            selectedFilter = item;
            selectedDate = item.value;
        }
    });


    const handleChangeDate = (date: MaterialUiPickersDate) => {
        if(null !== date){
            if (null !== selectedFilter) {
                dispatch(removeReportSelectFilterAction(selectedFilter));
            }
            dispatch(setReportSelectFilterAction(new SelectedFilter(filter, moment(date).format('Y-MM-DD'))));
            dispatch(setPageAction(0));
            dispatch(loadReport());
        }
    };

    const handleResetDate = () => {
        if (null !== selectedFilter) {
            dispatch(removeReportSelectFilterAction(selectedFilter));
            dispatch(setPageAction(0));
            dispatch(loadReport());
        }
    };

    return (<>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                    className={classes.container}
                >
                <DatePicker
                    value={selectedDate}
                    onChange={handleChangeDate}
                    label={filter.label}
                    format="dd.MM.yyyy"
                />
                {null !== selectedDate &&
                <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={handleResetDate}
                >
                    <Delete/>
                </IconButton>}
                </Grid>
            </MuiPickersUtilsProvider>
        </>
    )
};


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            marginRight: theme.spacing(1),
            display: 'flex'
        }
    }));