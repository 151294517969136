import {FilterValue} from './FilterValue'

export type filterType = 'radio'|'checkbox'|'date'|'select'|'search'|'multiselect';

export class Filter {
    public label: string;
    public type: filterType;
    public alias: string;
    public fullLabel: string|null;
    public filterValues?: FilterValue[];

    constructor(
        label: string,
        type: filterType,
        alias: string,
        fullLabel: string|null,
        filterValues?: FilterValue[]
    ) {
        this.label = label;
        this.type = type;
        this.alias = alias;
        this.fullLabel = fullLabel;
        this.filterValues = filterValues;
    }

     getFullLabel(){
        return null !== this.fullLabel ? this.fullLabel : this.label
    }
}