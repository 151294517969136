import {connect} from "react-redux";
import {RootState} from "../reducers";
import TablePaginationComponent from "../components/TablePaginationComponent";
import { ThunkDispatch } from 'redux-thunk'
import { updateRowsPerPage, updatePagePagination} from '../actions/report';

const mapStateToProps = (state: RootState) => {
    return {
        totalRows: state.report.totalRows,
        rowsPerPage: state.report.rowsPerPage,
        page: state.report.page,
    }
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
    return {
       changeRowsPerPageCallback: (rowsPerPage: number) => {
             dispatch(updateRowsPerPage(rowsPerPage))
        },
        changePageCallback: (newPage: number) => {
            dispatch(updatePagePagination(newPage));
        }
    }
};

const TablePaginationContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(TablePaginationComponent);

export default TablePaginationContainer