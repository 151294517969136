import {Filter} from './Filter'

export class FilterGroup {
    public group: string;
    public items: Filter[];

    constructor(
        group: string,
        items: Filter[]
    ) {
        this.group = group;
        this.items = items;
    }
}