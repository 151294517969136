export type ColumnOrderDirections = 'asc' | 'desc' | null;

export class OrderColumn {
    public direction: ColumnOrderDirections;
    readonly parentColumnId: string;

    constructor(
        direction: ColumnOrderDirections,
        parentColumnId: string
    ){
        this.direction = direction;
        this.parentColumnId = parentColumnId;
    }
}



